@keyframes ldio-tsajfowb9ik {
  0% {
    transform: translate(9.59px, 56.17px) scale(0);
  }
  25% {
    transform: translate(9.59px, 56.17px) scale(0);
  }
  50% {
    transform: translate(9.59px, 56.17px) scale(1);
  }
  75% {
    transform: translate(56.17px, 56.17px) scale(1);
  }
  100% {
    transform: translate(102.75000000000001px, 56.17px) scale(1);
  }
}
@keyframes ldio-tsajfowb9ik-r {
  0% {
    transform: translate(102.75000000000001px, 56.17px) scale(1);
  }
  100% {
    transform: translate(102.75000000000001px, 56.17px) scale(0);
  }
}
@keyframes ldio-tsajfowb9ik-c {
  0% {
    background: #688649;
  }
  25% {
    background: #7a312a;
  }
  50% {
    background: #0081c1;
  }
  75% {
    background: #dd792f;
  }
  100% {
    background: #688649;
  }
}
.ldio-tsajfowb9ik div {
  position: absolute;
  width: 24.660000000000004px;
  height: 24.660000000000004px;
  border-radius: 50%;
  transform: translate(56.17px, 56.17px) scale(1);
  background: #688649;
  animation: ldio-tsajfowb9ik 1.7543859649122806s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio-tsajfowb9ik div:nth-child(1) {
  background: #dd792f;
  transform: translate(102.75000000000001px, 56.17px) scale(1);
  animation: ldio-tsajfowb9ik-r 0.43859649122807015s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-tsajfowb9ik-c 1.7543859649122806s infinite step-start;
}
.ldio-tsajfowb9ik div:nth-child(2) {
  animation-delay: -0.43859649122807015s;
  background: #688649;
}
.ldio-tsajfowb9ik div:nth-child(3) {
  animation-delay: -0.8771929824561403s;
  background: #dd792f;
}
.ldio-tsajfowb9ik div:nth-child(4) {
  animation-delay: -1.3157894736842104s;
  background: #0081c1;
}
.ldio-tsajfowb9ik div:nth-child(5) {
  animation-delay: -1.7543859649122806s;
  background: #7a312a;
}
.loadingio-spinner-ellipsis-c2saky3xck {
  width: 137px;
  height: 137px;
  display: inline-block;
  overflow: hidden;
}
.ldio-tsajfowb9ik {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-tsajfowb9ik div {
  box-sizing: content-box;
}
