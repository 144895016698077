@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@600&family=Zen+Kaku+Gothic+New:wght@500;700&display=swap");

:root {
  --my-font-family: "Zen Kaku Gothic New", "ヒラギノ角ゴ Pro W3", "HiraKakuPro-W3", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Arial, verdana, sans-serif;
  --my-theme-color: #688649;
  --my-theme-bg-color: #f5f4ee;
  --my-theme-header-footer-color: #a9c77f;
}

.App {
  background-color: var(--my-theme-bg-color);
  height: 100%;
  min-height: 100vh;
}

.body-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.body-scroll::-webkit-scrollbar {
  display: none;
}

[data-amplify-theme] {
  --amplify-components-authenticator-router-background-color: #ffffff !important;
  --amplify-components-authenticator-modal-background-color: #f5f4ee !important;
  --amplify-components-tabs-item-active-color: var(--my-theme-color) !important;
  --amplify-components-tabs-item-focus-color: var(--my-theme-color) !important;
  --amplify-components-tabs-item-hover-color: var(--my-theme-color) !important;
  --amplify-components-tabs-item-active-border-color: var(--my-theme-color) !important;
  --amplify-components-checkbox-icon-background-color: var(--my-theme-color) !important;
  --amplify-components-checkbox-button-focus-box-shadow: var(--my-theme-color) !important;
  --amplify-components-button-primary-background-color: var(--my-theme-color) !important;
  --amplify-components-button-primary-active-background-color: var(--my-theme-color) !important;
  --amplify-components-button-primary-visited-background-color: var(--my-theme-color) !important;
  --amplify-components-button-primary-focus-background-color: var(--my-theme-color) !important;
  --amplify-components-button-primary-hover-background-color: var(--my-theme-color) !important;
  --amplify-components-button-link-color: var(--my-theme-color) !important;
  --amplify-components-button-link-hover-color: var(--my-theme-color) !important;
  --amplify-components-button-link-focus-color: var(--my-theme-color) !important;
  --amplify-components-button-link-active-color: var(--my-theme-color) !important;
  --amplify-components-button-link-hover-background-color: initial !important;
  --amplify-components-button-link-active-background-color: initial !important;
  --amplify-components-radio-button-checked-color: var(--my-theme-color) !important;
  --amplify-fonts-default-static: var(--my-font-family) !important;
  --amplify-fonts-default-variable: var(--my-font-family) !important;
  --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-border-primary) !important;
  --amplify-components-button-hover-background-color: var(--amplify-colors-font-inverse) !important;
  --amplify-components-radio-button-checked-disabled-color: var(--my-theme-color) !important;
  --amplify-components-radio-button-disabled-border-color: var(--amplify-components-radio-button-border-color) !important;
  --amplify-components-button-hover-color: var(--amplify-colors-font-primary) !important;
  --amplify-components-button-focus-color: var(--amplify-colors-font-primary) !important;
}
div[style*="background-color: rgb(4, 125, 149)"] {
  background-color: var(--amplify-components-button-primary-background-color) !important;
}
p[style*="color: rgb(4, 125, 149)"] {
  color: var(--amplify-components-button-primary-background-color) !important;
}
span[style*="color: rgb(4, 125, 149)"] {
  color: var(--amplify-components-button-primary-background-color) !important;
}
svg[style*="color: rgb(4, 125, 149)"] {
  color: var(--amplify-components-button-primary-background-color) !important;
}
[data-amplify-authenticator] {
  max-width: initial !important;
  max-height: 100vh;
  overflow: scroll;
}

[data-size=small].amplify-input {
  align-items: center;
}

.ff-min {
  font-family: "Shippori Mincho", serif !important;
}
body {
  margin: 0;
  font-family: var(--amplify-fonts-default-variable);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--my-theme-bg-color);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.auth-screen {
  position: relative;
  width: 100%;
  min-height: 100vh;
  /* background: url("/assets/background.png"); */
  background-size: cover;
}

.auth-form {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-logo {
  width: 100%;
}
.loading-screen {
  position: fixed;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-family: var(--amplify-fonts-default-variable) !important;
}
label {
  font-size: 16px;
}
.custom-checkbox-area {
  max-height: 300px !important;
  overflow: scroll !important;
}
.custom-checkbox .amplify-checkbox {
  font-size: 20px !important;
  padding: 10px 0;
  margin: 0 3px;
}
.header-transition,
.header-transition img,
.header-transition p,
.header-transition .amplify-icon {
  transition: 0.3s ease-out !important;
}
.menu-transition {
  transition: 0.3s ease-out !important;
}
.loading-transition {
  transition: 0.3s ease-out !important;
}
.header-position {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}
.header-pc-min2 {
  top: -10px !important;
}
.menu:hover {
  background-color: #e8e8e8;
}
.hamburger:hover {
  filter: brightness(90%);
}
.tab .amplify-icon {
  height: 2px !important;
  width: 2px !important;
}
.tab:hover .amplify-icon {
  height: 2px !important;
  width: 136px !important;
}
.subtab:hover p {
  color: var(--my-theme-color) !important;
}
.nav img:not(.nav-badge) {
  filter: brightness(140%) saturate(0%) !important;
}
.nav p {
  color: #a0a0a0 !important;
}
.nav:hover img {
  filter: brightness(100%) saturate(100%) !important;
}
.nav:hover p {
  color: var(--my-theme-color) !important;
}
.nav-active img {
  filter: brightness(100%) saturate(100%) !important;
}
.nav-active p {
  color: var(--my-theme-color) !important;
}

.loading-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.tablet-howto {
  transform: scale(0.7) !important;
  transform-origin: left top !important;
  left: calc(calc((100vw - 980px) / 2) + calc(980px * 0.3 / 2));
  position: relative !important;
  height: 3700px;
}

input {
  background-color: #ffffff !important;
}

input:disabled {
  background-color: #E9EBEC !important;
  color: #636B75 !important;
}

select {
  background-color: #ffffff !important;
}
textarea {
  background-color: #ffffff !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.custom-ui {
  position: relative;
  text-align: center;
  left: -5px;
}
.react-confirm-alert-overlay-custom {
  background: rgba(0, 0, 0, 0.4) !important;
  animation: react-confirm-alert-fadeIn 0s 0s forwards !important;
}

.react-confirm-alert-overlay{
  background: rgba(0, 0, 0, 0.4) !important;
  animation: react-confirm-alert-fadeIn 0s 0s forwards !important;
}

.pointer {
  cursor: pointer !important;
}
.content {
  padding: 0px;
}
.pccomponent {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100vw !important;
  max-width: 960px !important;
}
.maxwidth800 {
  max-width: 800px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
}
.maxwidth600 {
  max-width: 600px !important;
}
.passwordField {
  margin: 16px auto !important;
}
.ListSingleLineA {
  margin: 16px;
}
.TextFieldA {
  width: 100% !important;
  height: auto !important;
}
.SelectFieldA {
  width: 100% !important;
  height: auto !important;
}
.ButtonCancelSave {
  width: auto;
  margin: 16px !important;
}
.required {
  vertical-align: top;
  color: red;
}
.PrimaryButtonIcon {
  width: fit-content !important;
  margin: 16px auto !important;
}
.ListTwolineA {
  width: auto !important;
  margin: 16px 0 0 0 !important;
}
.push {
  height: 150px;
}
.homepage {
  margin-bottom: -80px;
}
.ListSingleLineB {
  width: auto !important;
  margin: 0 16px 0 16px;
}
.NavBar {
  position: fixed;
  bottom: -1px;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.radio-swapper .amplify-radiogroup {
  width: 100%;
  overflow-y: auto;
  padding: 0 2px;
  cursor: pointer;
}

.radio-swapper .amplify-radiogroup label.amplify-radio {
  width: 100%;
  justify-content: flex-end;
}

.radio-swapper .amplify-radiogroup label.amplify-radio .amplify-radio__label {
  width: calc(100% - 50px);
  text-align: left;
  text-overflow: ellipsis !important;
  white-space: pre;
  overflow: hidden;
  display: block;
}

.checkbox-list-swapper {
  width: 100%;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 0 2px;
}

.checkbox-item label{
  width: 100%;
  justify-content: flex-end;
}

.checkbox-item label .amplify-checkbox__label {
  width: calc(100% - 50px);
  text-align: left;
  text-overflow: ellipsis !important;
  white-space: pre;
  overflow: hidden;
  display: block;
}

.word-break {
  word-break: break-all !important;
}
@media screen and (max-height: 500px) {
  .NavBar {
    position: relative;
    bottom: -1px;
  }
}
.colummenu {
  z-index: 10 !important;
  position: absolute !important;
  margin: -30px 10px 0 0 !important;
}
.fadein {
  animation: fadeIn 0.3s 0s forwards !important;
}

.hide-scroll {
   overflow-x: hidden !important;
}

@keyframes fadeIn {
  /*animation-nameで設定した値を書く*/

  0% {
    opacity: 0;
  } /*アニメーション開始時は不透明度0%*/

  100% {
    opacity: 1;
  } /*アニメーション終了時は不透明度100%*/
}

form[data-amplify-authenticator-confirmsignup] p:not(.confirmSignUp) {
  font-size: 0;
}
.confirmSignUp {
  white-space: pre-wrap;
}

.swapper {
  display: block;
  max-width: 960px;
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  padding: 0px 32px 32px 32px;
  border-radius: 4px;
  margin-top: 32px;
  margin-bottom: 32px;
  overflow: clip;
  position: relative;
  height: 100%;
}

.swapperMindTypeCheck {
  max-width: 800px
}

.content-swapper {
  display: flex;
  justify-content: space-between;
  max-height: calc(100vh - 415px);
  min-height: 300px;
  overflow: hidden;
  margin-top: 16px;
}

.min-height-0 {
  min-height: 0px;
}

.list-lineD-swapper {
  display: flex;
  max-width: 288px;
  width: 100%;
  flex-direction: column;
  gap: 0;
  overflow: auto;
  min-height: 300px;
  max-height: calc(100vh - 414px);
}

.column-child--orange {
  background-color: #DD792F;
}

.column-child {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.column-child__img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  z-index: 0;
}

.column-child__icon {
  width: 25px;
  height: 25px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.vertical-elipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  word-break: break-all !important;
  overflow: hidden !important;
}

.list-attachment .attachment-item:first-child .item-border-top {
  background-color: transparent !important;
}

form[data-amplify-authenticator-signup] button[type="submit"]{
  font-size:0
}
form[data-amplify-authenticator-signup] button[type="submit"]:before{
  content:"アカウントを無料で登録";
  font-size:16px;
}

.radio-custom .amplify-flex.amplify-radiogroup {
  width: 100%;
  gap: 8px;
}

.disable-touch {
  touch-action: none !important;
  -ms-touch-action: none !important;
}

.width100{
  width: 100% !important;
}

.share-checkbox label {
  gap: 16px;
}

.overflow-hidden {
  overflow: hidden;
}

.area-h58 textarea{
  height: 58px;
}

.area-h65 textarea{
  height: 65px;
}

.challenge-edit p,
.challenge-edit span {
  color: rgb(13, 26, 38) !important;
}

.amplify-button:focus {
  box-shadow: none !important;
}

.outline-checkbox .amplify-checkbox__icon--checked {
  background-color: transparent !important;
  border: 1px solid #688649;
}
.outline-checkbox .amplify-icon > svg {
  color: #688649;
  width: 16px;
  height: 18px;
  padding-bottom: 1px
}

.cursor-drag {
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important;
  cursor: grab !important;
}

.cursor-drag:active {
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
}

@keyframes toast-in-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.guide-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0px;
  overflow: hidden;  
  pointer-events: none;
  z-index: 9;
}

.guide-wrapper {
  position: relative;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0px;
  min-height: 100%;
  background: transparent;
  /* padding: 16px; */
}

.right-bottom {
  transition: all 1s ease;
  animation: toast-in-bottom .7s;
}

.most-front {
  z-index: 100;
}

.guide-content-pc {
  position: absolute;
  bottom: 48px;
  right: 33px;
  pointer-events: all;
}

.guide-content-mb {
  position: absolute;
  bottom: 90px;
  display: flex;
  pointer-events: all;
}

.guide-content-mb-adjust-bucketlistguidancedialog {
  bottom: -35px !important;
}

.guide-content-center-mb {
  width: 100%;
  justify-content: center;
}

.close-button-wrapper {
  background-color: #ffffff !important;
  border: 2px solid #AEB3B7 !important;
  box-shadow: 0px 3px 12px rgba(13, 26, 38, 0.16);
  border-radius: 100%;
}

.close-button-wrapper .close-icon-modal {
  top: 7px !important;
  left: 7px !important;
}

.display-none {
  display: none !important;
}

.fabtq-wrapper-pc, .fabtq-wrapper-mb  {
  padding: 10px 7px !important;
  background: #FFFFFF;
  border: 1px solid #AEB3B7;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex !important;
  align-items: center !important;
  height: auto !important;
  /* position: absolute !important; */
}

.fabtq-wrapper-pc {
  width: 195px !important;
  height: 500px;
}
.batler-wrapper {
  width: 180px !important;
  right: 16px;
}
.butler-wrapper-pc {
  width: 195px !important;
  height: 500px;
  position: absolute;
  right: -100%;
}
.fabtq-wrapper-mb {
  width: 180px !important;
  right: 16px;
}

.fabtq-content-pc, .fabtq-content-mb {
  border: none !important;
  position: relative !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  inset: 0 !important;
  flex: 1;
}

.fabtq-content-pc {
  margin-left: 30px !important;
}
.fabtq-content-mb {
  margin-right: 10px !important;
}

.tq-icon-pc {
  width: 80px !important;
  height: 80px !important;
  left: -45px !important;
  top: -15px !important;
}

.tq-icon-mb {
  width: 72px !important;
  height: 72px !important;
  left: -37px !important;
  top: -15px !important;
}

.butler-float-icon {
  left: 178px !important;
}
.butler-float-pc-icon {
  left: 170px !important;
}

.auth-dialog-mb {
  bottom: 19px !important;
}

*:focus {
  outline: none;
}

.MindTypeCheckField {
  width: 90%;
  margin-left:200px;
  position: relative;
  top: -80px;
}
.MindTypeCheckFieldSP {
  width: 90%;
  position: relative;
  top: -120px;
}

.MindTypeAnswerDefault {
  background-color: #f3f3f3;
  color: #000000;
}

.MindTypeAnswerHighlighted {
  background-color: rgb(198,231,186);
  color: #000000 !important;
}

.informationPC div {
  width: 1070px;
  padding: 8px;
  padding-bottom: 4px;
  border: 2px solid #6b6666;
  background-color: #ffffff;
  text-align: center;
}
.informationPC div h1 {
  margin: 0px;
  color: red;
  font-weight: bold;
  text-decoration: underline;
  font-size: 18px;
}

.information div {
  width: 325px;
  margin: 0 auto;
  padding: 8px;
  padding-bottom: 4px;
  border: 2px solid #6b6666;
  background-color: #ffffff;
  text-align: center;
}
.information div h1 {
  margin: 0px;
  color: red;
  font-weight: bold;
  text-decoration: underline;
  font-size: 18px;
}
